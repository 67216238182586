import React, { useEffect, useState } from 'react';
import './Package.css';

const PremiumPackage = () => {
     const [packages, setPackages] = useState([]);
     const [selectedPackage, setSelectedPackage] = useState({
          name: 'Product Name',
          price: 0,
          numberEye: 'One eye'
     });
     const [isOpen, setIsOpen] = useState(false);

     useEffect(() => {
          fetch('premiumPackage.json')
               .then((res) => res.json())
               .then((data) => setPackages(data));
     }, []);

     const toggleDropdown = () => {
          setIsOpen(!isOpen);
     };

     const handleSelectPackage = (p) => {
          setSelectedPackage(p);
          setIsOpen(false);
     };

     return (
          <div className={`transform md:hover:scale-105 transition-transform duration-300 ease-in-out shadow-xl relative z-10 hover:z-40`}>
               <div className="rounded-lg bg-slate-50 relative">

                    <div className='bg-gradient-to-b from-[#410c74] to-purple-900 pb-10 px-14 rounded-lg rounded-br-[25%] rounded-bl-[25%]'>
                         <div className=''>
                              <h1 className='text-3xl w-[286px] px-0 text-center py-10 font-bold text-slate-50'>PREMIUM PACKAGE</h1>
                         </div>
                         <div>
                              <div>
                                   <div className='flex justify-center'>
                                        <div className="dropdown relative">
                                             <div className=''>
                                                  <div onClick={toggleDropdown} className="text-xl font-sans font-bold uppercase dropdown-btn cursor-pointer px-10 py-5 mb-10 bg-white">
                                                       Select Package
                                                  </div>
                                             </div>
                                             {isOpen && (
                                                  <div className="dropdown-menu grid grid-cols-2 absolute top-[68px] mx-auto bg-gray-200 w-80 mr-2 pl-3 pb-3">
                                                       {packages.map((p) => (
                                                            <p
                                                                 key={p.name}
                                                                 onClick={() =>
                                                                      handleSelectPackage({
                                                                           name: p.name,
                                                                           price: p.price,
                                                                           numberEye: p.numberEye,
                                                                      })
                                                                 }
                                                                 className="dropdown-item cursor-pointer hover:underline py-2 px-1"
                                                            >
                                                                 {p.name}
                                                            </p>
                                                       ))}
                                                  </div>
                                             )}
                                        </div>
                                   </div>
                                   <div className=''>
                                        <div className="selected-package">
                                             <h2 className='text-white text-xl text-center'>{selectedPackage.name}</h2>
                                             <h5 className='text-white text-base text-center mt-4'>{selectedPackage.numberEye}</h5>
                                             <p className='text-white text-2xl text-center mt-4'>Price : {selectedPackage.price} BDT</p>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>

                    <div className='p-10 text-center '>
                         <div className='flex space-x-4 items-center pt-4'>
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                   <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                              </svg>
                              <p className='text-xl text-black'>Airport Reception</p>
                         </div>
                         <div className='flex space-x-4 items-center pt-4'>
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                   <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                              </svg>
                              <p className='text-xl text-black'>Medical Assistance</p>
                         </div>
                         <div className='flex space-x-4 items-center pt-4'>
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                   <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                              </svg>
                              <p className='text-xl text-black'>VIP Medical Support</p>
                         </div>
                         <div className='flex space-x-4 items-center pt-4'>
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                   <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                              </svg>
                              <p className='text-xl text-black'>Transportation</p>
                         </div>
                         <div className='flex space-x-4 items-center py-4'>
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                   <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                              </svg>
                              <p className='text-xl text-black'>Accommodation</p>
                         </div>
                    </div>
               </div>
          </div>
     );
};

export default PremiumPackage;
