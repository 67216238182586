import React from 'react';
import surgerybanner from '../../../images/surgerypageimage/eye-chart-caucasian-woman.jpg';

const SurgeryBanner = () => {
     return (
          <section className='mt-0 '>
               <div className="hero  min-h-screen bg-gradient-to-r from-teal-900 from-20% via-slate-800 via-50% to-teal-900 to-90% ">
                    <div className="hero-content flex-col lg:flex-row-reverse">
                         <img className='w-full rounded-md lg:w-1/3 ' src={surgerybanner} alt="banner" />
                         <div className='lg:w-2/3 lg:pr-20 '>
                              <h1 className=" text:3xl md:text-6xl text-cyan-500 font-bold">Revolutionary Eye Surgery Techniques for Crystal-Clear Vision
                              </h1>
                              <p className="py-7 text-slate-50 text-justify">Are you tired of relying on glasses or contacts to see the world around you? Look no further than Vision Eye Hospital, where we offer cutting-edge eye surgery techniques to elevate your vision to a whole new level.</p>

                         </div>
                    </div>
               </div>
          </section>
     );
};

export default SurgeryBanner;