import { CheckIcon } from '@heroicons/react/24/solid';
import React from 'react';

const SurseryOption = () => {
     return (
          <section>
               <div className="hero min-h-screen bg-gray-800">
                    <div className="hero-content flex flex-col lg:flex-row ">
                         <div className="text-center lg:text-left lg:w-2/5 ">
                              <h1 className="text-3xl  lg:text-5xl  leading-tight text-slate-100 font-bold ">Revolutionizing Eye Surgery: Now at <span className='text-blue-300 hover:text-blue-400'> Vision Eye Hospital</span></h1>
                         </div>
                         <div className=' w-full lg:w-3/5   '>
                              <div className="card rounded w-full lg:w-10/12  h-2/3 flex justify-center py-4 lg:py-8 bg-gradient-to-r from-zinc-700 via-pink-500 to-zinc-500  shadow-xl">
                                   <div className="card-body px-12">
                                        <h2 className="card-title text-slate-100 text-3xl font-bold">Surgery Options</h2>

                                        <div className='flex items-center justify-center pt-5'>

                                             <CheckIcon className='w-8 h-8 text-pink-500 stroke-current stroke-2' />
                                             <p className='text-3xl font-semibold
                                              text-slate-100 pl-8'>ICL & ICL(Toric)
                                             </p>
                                        </div>
                                        <div className='flex items-center justify-center pt-5'>

                                             <CheckIcon className='w-8 h-8 text-pink-500 stroke-current stroke-2' />
                                             <p className='text-3xl font-semibold
                                              text-slate-100 pl-8'>IPCL & IPCL (Toric)
                                             </p>
                                        </div>
                                        <div className='flex items-center justify-center pt-5'>

                                             <CheckIcon className='w-8 h-8 text-pink-500 stroke-current stroke-2' />
                                             <p className='text-3xl font-semibold
                                              text-slate-100 pl-8'>SMILE
                                             </p>
                                        </div>
                                        <div className='flex items-center justify-center pt-5'>

                                             <CheckIcon className='w-8 h-8 -mt-5 text-pink-500 stroke-current stroke-2' />
                                             <p className='text-3xl font-semibold
                                              text-slate-100 pl-8'>Femto LASIK & Conventional LASIK</p>
                                        </div>
                                        <div className='flex items-center justify-center pt-5'>

                                             <CheckIcon className='w-8 h-8 text-pink-500 stroke-current stroke-2' />
                                             <p className='text-3xl font-semibold
                                              text-slate-100 pl-8'>LASIK EXTRA</p>
                                        </div>
                                   </div>
                              </div>
                         </div>

                    </div>

               </div>
          </section>
     );
};

export default SurseryOption;