import React from 'react';

const BottomFooter = () => {
     return (
          <div className='bg-lime-600 text-center py-2 text-gray-50'>
               <p>
                    Copyrighted to © CEO MKA Saimon 2023 | All Rights Reserved by site creator Surmee.

               </p>
          </div>
     );
};

export default BottomFooter;