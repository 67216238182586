import React, { useState } from 'react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/solid';
import LinkNav from '../LinkNav/LinkNav';
import logo from '../../../images/logo1.PNG';
import { Link } from 'react-router-dom';




const Navbar = () => {
     const [open, setOpen] = useState(false);
     const routes = [
          { id: 1, name: 'HOME', path: '/' },
          { id: 2, name: 'DOCTORS', path: '/doctors' },
          { id: 3, name: 'SURGERY PACKAGES', path: '/surgery' },
          { id: 4, name: 'ABOUT', path: '/about' },
          { id: 6, name: 'REVIEWS', path: '/reviews' },
          { id: 7, name: 'CONTACT', path: '/contact' },

     ];
     const appointmentRoute = [
          { id: 69473, name: 'Book An appointment', path: '/appointment' },
     ];

     return (
          <div className=' bg-slate-50 py-10'>
               <div className='flex  items-center justify-between mx-auto w-[80%]'>

                    <div className='hidden md:block ml-5'>
                         <img src={logo} alt="" />
                    </div>
                    <div className='md:hidden'>
                         <h1 className='text-xl text-center md:text-3xl font-bold font-serif text-teal-600 '>International Patients Support Team  </h1>
                         <p className='text-lg  sm:text-2xl text-center font-semibold text-cyan-900'>Vision Eye Hospital Dhaka</p>
                    </div>

                    <nav className='bg-slate-50 py-1 z-50 relative'>
                         <ul className={` sm:flex w-[400px] sm:w-full py-3 text-center text-white justify-evenly absolute  sm:static bg-slate-50  md:h-16 items-center duration-700 ease-in ${open ? 'top-[100px] -right-[20px] ' : 'hidden'}`}>
                              {routes.map(route => <LinkNav key={route.id}
                                   setOpen={setOpen}
                                   route={route}></LinkNav>)}
                         </ul>

                         <div onClick={() => setOpen(!open)} className="h-8 w-8 m-2 text-white sm:hidden">
                              {
                                   open ? <XMarkIcon className='text-zinc-900' ></XMarkIcon>
                                        : <Bars3Icon className='text-zinc-900'></Bars3Icon>
                              }
                         </div>

                    </nav>

                    <Link to={appointmentRoute[0].path}>
                         <button className='hidden lg:block text-lg font-semibold hover:text-white uppercase bg-white hover:bg-fuchsia-900 px-8 py-5 rounded-full border-4 border-fuchsia-900'>
                              {appointmentRoute[0].name}
                         </button>
                    </Link>

               </div>
          </div>


     );
};

export default Navbar;