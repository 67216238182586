import React from 'react';


const Header = () => {
     return (
          <div className=' bg-slate-50 py-8'>
               <div className=' md:w-[80%] mx-auto  flex items-center justify-between   '>
                    <div className='hidden sm:flex  space-x-2 '>
                         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="bg-fuchsia-900 text-white p-1 rounded-full font-bold w-8 h-8">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                         </svg>
                         <div className='flex flex-col md:flex-row space-x-1'>
                              <p className='w-[40%]'>For a free consultation:</p>
                              <p className='w-[60%]'>+880 1871977759</p>
                         </div>

                    </div>
                    <div className='hidden lg:block'>
                         <h1 className='text-xl text-center md:text-3xl font-bold font-serif text-teal-600 '>International Patients Support Team  </h1>
                         <p className='text-lg  sm:text-2xl sm:text-center font-semibold text-cyan-900'>Vision Eye Hospital Dhaka</p>
                    </div>
                    <div>
                         <div className='flex items-center pl-2'>
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="bg-fuchsia-900 text-white p-1 rounded-full font-bold w-8 h-8">
                                   <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                              </svg>
                              <p className='ml-2'>visioneyeint@gmail.com</p>
                         </div>

                    </div>
               </div>

          </div>
     );
};

export default Header;