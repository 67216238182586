import React from 'react';
import dhakacity from '../../../images/WhyDhaka/dhakacity.png';
import dhakateam from '../../../images/WhyDhaka/dhakateam.png';
import committed from '../../../images/WhyDhaka/committed.png';
import care from '../../../images/WhyDhaka/care (1).png';
import SingleReason from './SingleReason/SingleReason';

const Reason = () => {
     const reasons = [
          {
               _id: 1,
               title: 'Access to Quality Eye Care Services in Dhaka',
               details: 'Dhaka, the capital city of Bangladesh, is home to a large population of people in need of eye care services. Fortunately, Vision Eye Hospital is located in Dhaka, making it easily accessible to a large number of patients. Equipped with the latest technology and staffed by highly skilled eye care professionals, we offer a comprehensive range of services to meet the needs of our patients. Our personalized approach to care ensures that each patient receives individualized attention and the best possible outcomes for their eye health.',
               image: dhakacity
          },
          {
               _id: 2,
               title: 'Expert Eye Care from Skilled Specialists',
               details: 'Vision Eye Hospital has a team of experienced and highly qualified eye specialists who use state-of-the-art technology to provide personalized and comprehensive eye care services to their patients.',
               image: dhakateam
          },
          {
               _id: 3,
               title: 'Affordable and Compassionate Eye Care Services',
               details: 'Vision Eye Hospital is committed to providing affordable pricing and financing options to make their services accessible to everyone. They also prioritize their patients needs and comfort, ensuring that they receive the highest level of care possible.',
               image: committed
          },
          {
               _id: 4,
               title: 'Personalized and Comprehensive Eye Care in Dhaka',
               details: 'At Vision Eye Hospital, we are dedicated to providing personalized and comprehensive eye care services to our patients in Dhaka and beyond. Our team of eye care professionals uses the latest technology and techniques to diagnose and treat a wide range of eye conditions. We believe that everyone deserves access to quality eye care, and we strive to make that a reality for our patients.',
               image: care
          },
     ]
     return (
          <div className='bg-gradient-to-b from-lime-50 from-10% via-green-300 via-30% to-cyan-400 to-90% mt-28  pt-14 lg:pb-32'>
               <div className='flex justify-center pb-5'>
                    <div className='w-4/5 '>
                         <h1 className='text-3xl md:text-5xl text-center font-bold text-sky-800'>Why Dhaka</h1>
                         <p className='pt-6 pb-10 text-lg md:text-xl text-center font-semibold font-sans text-cyan-600'>Dhaka is a popular destination for eye treatment due to its high-quality, affordable, and accessible eye care services, skilled and experienced eye specialists, and advanced technology and techniques.
                         </p>
                    </div>
               </div>


               <div>
                    {
                         reasons.map(singleReason => <SingleReason
                              key={singleReason._id}
                              singleReason={singleReason}

                         ></SingleReason>)
                    }
               </div>

          </div>
     );
};

export default Reason;