import React from 'react';
import topbanner from '../../../images/aboutsaimonbanner.jpeg';
import { Link } from 'react-router-dom';


const AboutTopBanner = () => {
     return (
          <main>
               <section>
                    <div className="hero h-[450PX]" style={{ backgroundImage: `url(${topbanner})` }}>
                         <div className="hero-overlay bg-opacity-60"></div>
                         <div className=" text-center text-neutral-content">
                              <div className="max-w-xl">
                                   <h1 className="mb-5 text-2xl font-semibold sm:text-5xl sm:font-bold">South Asian Dynamic Eye Hospital</h1>
                                   <p className="mb-5 text-base sm:text-xl ">Vision Eye Hospital in Bangladesh offers top-tier eye care with experienced doctors specializing in Glaucoma, Vitro-Retina, Oculoplastic, Uvea, Laser DCR, Phaco, ROP, LASIK, ICL Refractive Surgery, Ocular Oncology, Low Vision, Vision Therapy, and Pediatric Ophthalmology.</p>

                              </div>
                         </div>
                    </div>
               </section>
               <section>
                    <section className='bg-yellow-300 h-44  sm:h-40 px-2 sm:px-0  flex items-center justify-evenly flex-wrap' >
                         <h2 className="text-3xl font-sans font-bold text-slate-900">Looking for a First-Class <span className="text-rose-900"> ABROAD EYE HOSPITAL</span> ?</h2>
                         <Link to='/appointment'>
                              <button className="bg-rose-700 hover:bg-white font-sans font-semibold text-xl py-4 px-8 rounded text-white hover:text-red-700 ease-in duration-300">Contact us
                                   <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="ml-3 w-6 h-6 font inline">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                                   </svg>

                              </button>
                         </Link>
                    </section>

               </section>
          </main>
     );
};

export default AboutTopBanner;