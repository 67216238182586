import React from 'react';
import { PhoneArrowDownLeftIcon, MapPinIcon, EnvelopeIcon, ClockIcon } from '@heroicons/react/24/solid';
import Contact from './Contact';

const BottomContact = () => {

     const contactInfo = [
          {
               _id: 2837590,
               icon: <PhoneArrowDownLeftIcon className='w-12 h-12 text-sky-900 mb-4' />,
               title: 'EMERGENCY',
               info1: '+880 1871977759',
               info2: '',
               bgColor: 'bg-gradient-to-r from-cyan-300 to-lime-300 '
          },
          {
               _id: 283759430,
               icon: <MapPinIcon className='w-12 h-12 mb-4' />,
               title: 'LOCATION',
               info1: '229 Green Rd, Dhaka 1205',
               info2: '',
               bgColor: 'bg-gradient-to-r from-yellow-200 to-blue-200 '
          },
          {
               _id: 2837340,
               icon: <EnvelopeIcon className='w-12 h-12 text-sky-900 mb-4' />,
               title: 'EMAIL',
               info1: 'visioneyeint@gmail.com',
               info2: '',
               bgColor: 'bg-gradient-to-r from-cyan-200 to-blue-400 '
          },
          {
               _id: 283759534,
               icon: <ClockIcon className='w-12 h-12 mb-4' />,
               title: 'WORKING HOURS',
               info1: '24/7 Days Open',
               info2: '',
               bgColor: 'bg-gradient-to-r from-lime-500 to-green-200 '
          }
     ];

     return (
          <div className='mb-14'>
               <div className='text-center'>
                    <p className='font-bold text-2xl text-[#159EEC] font-sans'>GET IN TOUCH</p>
                    <h2 className='text-2xl text-[#1F2B6C]'>Contact</h2>
               </div>
               <div className='w-5/6 mx-auto grid grid-cols xl:grid-cols-4 gap-2  justify-center items-center mt-16'>
                    {contactInfo.map(contact => <Contact
                         key={contact._id}
                         contact={contact}
                    ></Contact>)}
               </div>
          </div>
     );
};

export default BottomContact;
