import React from 'react';
import surgeryPic from '../../../images/WhyDhaka/surgerypic.jpg';


const ContactSection = () => {
     return (
          <section className='px-2 md:w-5/6 md:mx-auto md:flex py-10'>
               <div className=' w-full md:w-2/5 mb-5  '>
                    <img className='rounded-lg -mt-16' src={surgeryPic} alt="" />
               </div>

               <div className='w-full md:w-3/5 md:px-20'>
                    <div>
                         <h1 className='text-3xl font-semibold mb-5 text-pink-900'>Eye Surgery: Improving Vision and Eye Health</h1>
                         <div>
                              <ul className=' ml-5 md:ml-0  flex flex-col'>
                                   <li className='text-lg list-disc'> Eye surgery is a medical procedure performed to treat various eye conditions, such as cataracts, glaucoma, and refractive errors.</li>
                                   <li className='text-lg list-disc'>Different types of eye surgery include LASIK, cataract surgery, corneal transplant, and retinal detachment surgery.</li>
                                   <li className='text-lg list-disc'>While eye surgery is generally safe and effective, it is important to discuss potential risks and benefits with an eye doctor before undergoing any procedure.</li>
                              </ul>
                         </div>

                    </div>
               </div>
          </section>
     );
};

export default ContactSection;