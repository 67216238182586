import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './LinkNav.css';

const LinkNav = ({ route, setOpen }) => {


     const [isFocused, setIsFocused] = useState(false);

     return (
          <div className='text-lg font-mono'>
               <li className='text-xl py-3 px-5 text-zinc-900'>
                    <Link
                         to={route.path}
                         className={`LinkNav__link ${isFocused ? 'LinkNav__link--focused text-lime-400' : ''}`}
                         onClick={() => setOpen(false)}
                         onFocus={() => setIsFocused(true)}
                         onBlur={() => setIsFocused(false)}
                    >
                         {route.name}
                    </Link>
               </li>
               <hr className='md:hidden ' />
          </div>
     );
};

export default LinkNav;




