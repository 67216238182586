import React from 'react';
import SurgeryBanner from '../SurgeryBanner/SurgeryBanner';
import SurseryOption from '../SurseryOption/SurseryOption';
import Spacialties from '../Spacialties/Spacialties';
import Pricingsection from '../PricingSection/Pricingsection';




const SurseryPage = () => {

     return (
          <div>
               <SurgeryBanner></SurgeryBanner>
               <SurseryOption></SurseryOption>
               <Pricingsection></Pricingsection>
               <Spacialties></Spacialties>


          </div>
     );
};

export default SurseryPage;