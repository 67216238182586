import React from 'react';
import bannereye from '../../../images/banner.png';
import bannerBg from '../../../images/topbannerbg.png';
import './Topbanner.css';
import { Link } from 'react-router-dom';


const Topbanneer = () => {
     return (
          <div>
               <div >
                    <div className="hero min-h-screen bg-base-200 hero-overlay" style={{ backgroundImage: `url(${bannerBg})` }} >
                         <div className='hero-overlay bg-white bg-opacity-95 flex items-center '>
                              <div className="hero-content w-[100%]   mx-auto flex items-center">
                                   <div className='  flex items-center flex-col-reverse  lg:flex-row-reverse'>
                                        <div className='lg:w-[50%] lg:px-10 pt-16 pb-24'>
                                             <img src={bannereye} className='rounded-lg lg:h-[550px] lg:-mt-28' alt='dd' />
                                        </div>
                                        <div className='md:w-[50%] px-5 lg:px-10 pb-10'>
                                             <h1 className='header-font text-center py-8 lg:mt-0 lg:text-start
                                             text-3xl lg:text-5xl lg:leading-[60px]  xl:text-[65px] font-normal xl:leading-[80px] mb-6 '>
                                                  Welcome To <br /> <span className='header-font  text-lime-400  hover:text-lime-600 '>Vision Eye Hospital</span>
                                             </h1>
                                             <p className='font-sans text-justify text-xl xl:text-2xl  text-stone-700   font-normal pb-10'>
                                                  Welcome to <span className='font-semibold'>Vision Eye Hospital</span> Your vision comes first. Our experienced team provides exceptional eye care, from check-ups to surgeries, using advanced technology. Trust us for personalized, high-quality treatment and a lifetime of clear vision.
                                             </p>
                                             <div className=' flex justify-center lg:justify-start' >
                                                  <Link to='/about'>
                                                       <button className='hidden md:block bg-lime-400 text-xl mt-2  text-white px-10 py-5 rounded-3xl font-bold font-sans shadow-lg hover:bg-white border hover:text-lime-400 hover:border hover:border-lime-400'>ABOUT US</button>

                                                  </Link>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     );
};

export default Topbanneer;
