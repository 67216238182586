import React from 'react';
import aboutsaimon from '../../../images/saimon.jpeg';
import zakia from '../../../images/doctorZakia.jpeg';

const AboutManager = () => {
     return (
          <div>

               <div class='bg-slate-50 mt-12'>
                    <div class='md:w-3/5 lg:w-2/3 mx-auto grid grid-cols-1 md:grid-cols-4 '>
                         <img class='w-full md:col-span-2' src={aboutsaimon} alt="managerpic" />
                         <div class='text-white bg-gradient-to-r from-yellow-600 to-amber-700 md:col-span-2 p-6 md:p-12'>
                              <h1 class='text-3xl font-bold'>International Relation Manager</h1>
                              <h3 class='text-2xl font-semibold pt-4'>M.K.A Saimon</h3>
                              <p class='text-xl text-justify  pt-4 md:leading-relaxed'>
                                   Our cherished International Patients Coordinator, M.K.A. Saimon, plays a vital role in delivering services with the highest level of quality control. He serves as the primary point of contact for patients, addressing their queries and assisting users and facilitators throughout their journey. M.K.A. Saimon collaborates closely with information sources, people, facilitators, and medical professionals during the conversion process. His exceptional coordination and distribution of information, including transfers, accommodations, travel, and visas, ensure a smooth and satisfactory experience for international patients. With dedication and expertise, he proves to be an indispensable asset in providing a seamless .
                              </p>
                         </div>
                    </div>
                    <div class='md:w-3/5 lg:w-2/3 mx-auto grid grid-cols-1 md:grid-cols-4  mt-12'>
                         <div class='text-white bg-gradient-to-r from-yellow-600 to-amber-700 md:col-span-2 p-6 md:p-12'>
                              <h1 class='text-4xl font-bold'>Vision EYE Doctor</h1>
                              <h3 class='text-2xl font-semibold  mt-2'>Dr. Zakia Farhana</h3>
                              <p class='text-xl text-justify mt-2'>
                                   Dr. Zakia Farhana, our Senior International Patients Coordinator, manages patients' needs within the hospital, from admission to post-operative care. She organizes post-discharge home care and analyzes patient satisfaction surveys for improvements. Driven by excellence, she implements patient care initiatives and collaborates with the hospital staff to ensure safety and high-quality care. Her dedication and valuable contributions make her an exceptional team player at Vision Eye Hospital.
                              </p>
                         </div>
                         <img class='w-full md:col-span-2' src={zakia} alt="doctorpic" />
                    </div>
               </div>
          </div>
     );
};

export default AboutManager;
