import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Spacialty = ({ spacialty }) => {
     const { id, name, description } = spacialty;
     const [showModal, setShowModal] = useState(false);

     const handleDetailsClick = () => {
          setShowModal(true);
     };

     const closeModal = () => {
          setShowModal(false);
     };

     useEffect(() => {
          if (showModal) {
               // When the modal is shown, disable scrolling on the background content
               document.body.style.overflow = 'hidden';
          } else {
               // When the modal is closed, re-enable scrolling on the background content
               document.body.style.overflow = 'auto';
          }
     }, [showModal]);

     return (
          <div className="p-10 bg-[#1F2B6C] rounded-xl mt-5">
               <p className="text-2xl hover:text-slate-400 text-slate-50 mb-5 text-center">{name}</p>
               <div className="flex justify-center">
                    <button
                         className="w-48 h-16 font-bold bg-slate-100 hover:bg-blue-200 rounded-md transition-colors duration-300"
                         onClick={handleDetailsClick}
                    >
                         DETAILS
                    </button>
               </div>

               {showModal && (
                    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                         <div className="relative bg-white p-3 md:p-6 rounded-lg w-[90%] md:max-w-md overflow-y-auto max-h-[80vh]">
                              <h2 className="text-xl font-bold mb-4">{name}</h2>
                              <p>{description}</p>
                              {/* Add other information you want to display in the modal */}
                              <button
                                   className="mt-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                                   onClick={closeModal}
                              >
                                   Close
                              </button>
                         </div>
                    </div>
               )}
          </div>
     );
};

export default Spacialty;
