import React from 'react';
import AboutManager from '../AboutManager/AboutManager';
import AboutTopBanner from '../AboutTopBanner/AboutTopBanner';

const AboutUs = () => {
     return (
          <div className='bg-slate-50' >
               <AboutTopBanner />
               <AboutManager />
          </div>
     );
};

export default AboutUs;