import React from 'react';

const Imageshowslider = () => {
     return (
          <div className='bg-indigo-900 text-white p-5 static'>
               <div className='text-center py-10 '>
                    <h2 className='text-3xl font-bold lg:text-5xl'>Say Goodbye To Your Glasses</h2>
               </div>
               <div className='flex justify-center rounded-2xl'>
                    <iframe
                         className='rounded-2xl  lg:w-3/6 xl:w-2/6'
                         width='560'
                         height='315'
                         src='https://www.youtube.com/embed/lfsKKSGwafY'
                         title='YouTube video player'
                         frameborder='0'
                         allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                         allowfullscreen
                    />

               </div>
               <div className='my-10 md:w-2/3 mx-auto'>
                    <p className='text-justify font-sans '>At Vission Eye Hospital, we prioritize your eye health and well-being. Our team of skilled ophthalmologists and staff provide comprehensive eye care services, including advanced treatments and surgeries for a variety of eye conditions. We are dedicated to using the latest technology and personalized care to achieve the best outcomes for our patients. With a compassionate and supportive approach, we strive to make every visit to our hospital a comfortable and positive experience. Trust us to help you maintain clear and healthy vision for a better quality of life.</p>
               </div>

          </div>
     );
};

export default Imageshowslider;
