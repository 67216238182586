import React, { useState } from 'react';
import PatientModal from './PatientModal';
import patientsData from '../../Jsonfile/patients.json';

const Reviews = () => {
     const [selectedPatient, setSelectedPatient] = useState(null);

     const handlePatientClick = (patient) => {
          setSelectedPatient(patient);
          document.body.style.overflow = 'hidden'; // Disable scrolling on the background page
     };

     const closeModal = () => {
          setSelectedPatient(null);
          document.body.style.overflow = 'auto'; // Enable scrolling on the background page when the modal is closed
     };

     return (
          <div>
               <div className='bg-gradient-to-r from-indigo-400 from-10% via-sky-500 via-30% to-emerald-500 to-90% flex justify-center py-5 p-3'>
                    <div className='text-center'>
                         <div className='max-w-lg'>
                              <h1 className='text-5xl text-zinc-100 font-bold'>Patient Reviews</h1>
                              <p className='py-6 text-zinc-100 text-2xl'>
                                   We take pride in providing exceptional eye care services and value the feedback from our patients. Here are some reviews from our satisfied patients who experienced improved vision and received compassionate care at Vision Eye Hospital.
                              </p>
                         </div>
                    </div>
               </div>

               <div className='container mx-auto flex justify-evenly flex-wrap p-5 bg-emerald-500'>
                    {patientsData.patients.map((patient) => (
                         <div
                              key={patient.id}
                              className='w-96 h-96 mt-10 cursor-pointer border border-gray-100 p-4 relative transform transition-transform hover:scale-105 0.5s ease-in'
                              onClick={() => handlePatientClick(patient)}
                              style={{ backgroundImage: `url(${patient.images[0]})`, backgroundSize: 'cover' }}
                         >
                              <div className=' bg-gray-50 p-2 rounded h-full opacity-75'>
                                   <h3 className='text-lg md:text-2xl font-bold text-center text-yellow-500' >{patient.name}</h3>
                                   <p className='text-sm md:text-lg pt-3 text-sky-900 text-center'>{patient.reviwPatient}</p>
                                   <div className='flex justify-center'>
                                        <button className='bg-gradient-to-r from-cyan-500 to-blue-500 px-2 py-3 text-bold text-gray-50 mt-3 rounded-lg text-sm md:text-lg uppercase '>Click for more Image</button>
                                   </div>
                              </div>
                         </div>
                    ))}
               </div>

               {/* Patient Modal */}
               <PatientModal patient={selectedPatient} onClose={closeModal} />
          </div>
     );
};



export default Reviews;
