import React from 'react';

const Appointment = () => {
     return (
          <div className='relative'>

               <div className="hero min-h-screen bg-slate-300">
                    <div className="hero-content flex-col lg:flex-col gap-0">
                         <div>
                              <h1 className='text-4xl md:text-6xl lg:text-7xl text-center mb-10'>
                                   Reach Us Anytime
                              </h1>
                         </div>
                         <div className='hero-content flex-col lg:flex-row gap-0'>
                              <div className="card flex-shrink-0 w-full max-w-lg shadow-2xl rounded-none bg-base-50">
                                   <div className="card-body p-12">
                                        <div>
                                             <h1 className='text-2xl  semi-bold font-serif'>Contacts  & Address</h1>
                                             <hr className='h-1 w-1/5 border-none bg-yellow-500 my-7' />
                                             <div className='flex flex-col lg:flex-row items-center justify-center mb-4'>
                                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-4">
                                                       <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                                                  </svg>
                                                  <p className=''>
                                                       <a href='tel:+8801871977759' className='text-lg text-black font-semibold font-sans '>
                                                            +880 1871977759
                                                       </a>
                                                  </p>
                                             </div>

                                             <div className='flex flex-col lg:flex-row items-center justify-center mb-4'>
                                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-4">
                                                       <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                                                  </svg>
                                                  <p >
                                                       <a href='mailto:visioneyeint@gmail.com' className='text-lg text-black font-semibold font-sans '>
                                                            visioneyeint@gmail.com
                                                       </a>
                                                  </p>
                                             </div>
                                             <div className='flex flex-col lg:flex-row items-center justify-center mb-4'>
                                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-4">
                                                       <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                                                       <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                                                  </svg>
                                                  <p className='text-lg text-black font-semibold font-sans '>229 Green Rd, Dhaka 1205</p>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                              <div className="card flex-shrink-0 w-full max-w-lg shadow-2xl rounded-none bg-base-100 gap-0">
                                   <div className="card-body p-12">
                                        <div>
                                             <h1 className='text-2xl  semi-bold font-serif'>Opening Hour</h1>
                                             <hr className='h-1 w-1/5 border-none bg-yellow-500 my-7' />
                                             <p className='text-lg text-black  font-sans mb-4'>Appointments and contact available anytime!</p>
                                             <p className='text-lg text-black font-semibold font-sans mb-4'>24/7 Service</p>
                                             <p className='text-lg text-black font-semibold font-sans mb-4'> We take care of your health</p>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>


               {/* location is started here */}

               <div className="hero min-h-screen bg-red-50">

                    <div className="hero-content flex-col lg:flex-col gap-0">
                         <div>
                              <h1 className='text-4xl md:text-6xl lg:text-7xl text-center mb-10'>
                                   Location
                              </h1>
                         </div>

                         <div className='hero-content flex-col lg:flex-row gap-0'>
                              <div className="card flex-shrink-0 w-full max-w-lg shadow-2xl rounded-none bg-base-50">


                                   <div className="card-body p-12">
                                        <div>
                                             <p className='text-2xl text-justify'>At Vision Eye Hospital, we are committed to providing exceptional eye care services. Our dedicated team of professionals is here to support you throughout your journey towards improved vision and eye health. Please don't hesitate to contact us for any inquiries or to schedule your appointment.</p>
                                        </div>
                                   </div>
                              </div>
                              <div className="card flex-shrink-0 w-full max-w-lg shadow-2xl rounded-none bg-base-100 gap-0">
                                   <div className="card-body">

                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3652.0060185234556!2d90.38296310788631!3d23.747164788817297!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755bf50403e9315%3A0xe9a237466083f711!2sVision%20Eye%20Hospital!5e0!3m2!1sen!2sbd!4v1690617735856!5m2!1sen!2sbd"
                                             width="100%" height="450" title="Map" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade">
                                        </iframe>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     );
};

export default Appointment;