import React from 'react';
import Packages from './packages';
import PremiumPackage from './PremiumPackage';

const Pricingsection = () => {
     return (
          <section className='w-full flex justify-center items-center py-10 md:bg-sky-900'>
               <div className="bg-gradient-to-r from-yellow-800 from-10% via-stone-800 via-50% to-yellow-900 to-90% rounded bg-white w-full
                md:w-10/12 card lg:card-side py-10 md:px-0 md:pr-10">
                    <div className="lg:w-1/5 p-10 card-body">
                         <h2 className="card-title text-3xl lg:text-7xl text-slate-50 font-bold">Choose your best Surgery plan</h2>
                    </div>

                    <div className='flex flex-col lg:flex-row justify-center space-y-5 lg:space-y-0 lg:-space-x-3'>
                         <div className='w-full md:w-1/2'>
                              <Packages />
                         </div>
                         <div className='w-full md:w-1/2'>
                              <PremiumPackage />
                         </div>
                    </div>
               </div>
          </section>
     );
};

export default Pricingsection;
