import React, { useEffect, useState } from 'react';
import Spacialty from './Spacialty';


const Spacialties = () => {
     const [spacialties, setSpacialties] = useState([]);
     useEffect(() => {
          fetch('spacialtiesDB.json')
               .then(res => res.json())
               .then(data => setSpacialties(data))
     }, []);

     return (
          <div className='mt-16'>
               <div className='text-center mb-10'>
                    <p className='text-[#159EEC] font-bold text-lg uppercase'>Enhancing Vision</p>
                    <h2 className='text-[#1F2B6C] text-3xl'>Our Specialized Services</h2>
               </div>
               <div className='grid sm:grid-cols-2 md:grid-cols-3  gap-2 px-10'>
                    {
                         spacialties.map(spacialty => <Spacialty
                              spacialty={spacialty}
                              key={spacialty.id}
                         ></Spacialty>)
                    }
               </div>


          </div>
     );
};

export default Spacialties;