import React from 'react';

const PatientModal = ({ patient, onClose }) => {
     if (!patient) return null;

     return (
          <div className='fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50'>
               <div className='bg-white p-8 max-w-4xl'>
                    <h2 className='text-3xl font-semibold mb-4 text-center'>{patient.name}</h2>
                    <div className="flex flex-row flex-wrap gap-5">
                         {patient.images.map((image, index) => (
                              <img key={index} className='w-32 h-32 md:w-60 md:h-60 rounded-lg object-cover' src={image} alt="patient-review" />
                         ))}
                    </div>
                    <button
                         className='mt-4 bg-green-500 text-white font-semibold py-2 px-4 rounded cursor-pointer'
                         onClick={onClose}
                    >
                         Close
                    </button>
               </div>
          </div>
     );
};

export default PatientModal;
