import React from 'react';

const Contact = ({ contact }) => {
     const { icon, title, info1, info2, bgColor } = contact;
     return (
          <div className={`px-2 py-4 rounded-xl shadow-xl ${bgColor}`}>
               <div className='flex items-center justify-center mb-6'>
                    {icon}
               </div>
               <div className='text-center'>
                    <p className='text-xl font-bold mb-2'>{title}</p>
                    <p className='mb-6 text-sm md:text-xl'>{info1}</p>
                    <p>{info2}</p>
               </div>
          </div>
     );
};

export default Contact;
