import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

const SpacialtyDetails = () => {
     const { specialtyid } = useParams();
     const [serviceDetails, setServiceDetails] = useState(null);

     useMemo(() => {
          fetch('/spacialtiesDB.json')
               .then(res => res.json())
               .then(data => {
                    const findDetails = data?.find(service => service?.id === specialtyid);
                    if (findDetails) {
                         setServiceDetails(findDetails);
                    } else {
                         setServiceDetails(null);
                    }
               })
     }, [specialtyid]);

     const { name, description, } = serviceDetails || {};

     return (
          <div className='bg-blue-200 md:mx-10 p-5 h-96 mb-0'>
               {serviceDetails ? (
                    <>
                         <h1 className='text-4xl font-serif py-10 font-bold text-center'>{name}</h1>

                         <p className='text-lg '>{description}</p>
                    </>
               ) : (
                    <p>No details found for this specialty</p>
               )}
          </div>
     );
};

export default SpacialtyDetails;