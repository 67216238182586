import React from 'react';

const SingleReason = ({ singleReason }) => {
     const { title, details, image } = singleReason;
     return (
          <div>
               <div className='flex pb-16 px-6 md:px-32 lg:px-56  flex-col md:flex-row '>
                    <img className='w-36 h-36 lg:mr-12' src={image} alt="reason1 pict" />
                    <div className='mt-5 md:mt-0'>
                         <h1 className='text-2xl md:text-3xl  pb-3 font-semibold text-gray-900'>{title}</h1>
                         <hr className="bg-pink-900  h-1 w-1/5 border-none" />
                         <p className='text-lg md:text-xl mt-3 text-justify '>{details}</p>
                    </div>
               </div>

          </div>
     );
};

export default SingleReason;