import React from 'react';
import Bottomcontact from '../BottomContact/Bottomcontact';
import ContactSection from '../ContactSection/ContactSection';
import Imageshowslider from '../Imageshowslider/Imageshowslider';
import Reason from '../Reason/Reason';
import Topbanner from '../Topbanner/Topbanner';




const Home = () => {
     return (
          <div className='bg-white'>
               <Topbanner></Topbanner>
               <Imageshowslider></Imageshowslider>
               <Reason></Reason>
               <ContactSection></ContactSection>
               <Bottomcontact></Bottomcontact>
          </div>
     );
};

export default Home;